.pageBG {
	background-color: rgb(83, 83, 83);
	min-height: 100vh;
	color:white;
}

.pageTitle {
	text-align: center;
	padding: 20px;
	color: white;
}

.welcomeText {
	max-width: 700px;
}

a {
	color: #21cc49;
}

a:hover {
	color: rgb(198, 53, 255);
}

.aboutBox {
	padding: 20px;
	min-width: 500px;
}

@media (max-width: 500px) {
	.aboutBox {
		min-width: 250px;
	}
}

.imageBox {
	/* padding-right: 10px; */
	padding-bottom: 10px;
}

img {
	/* border-width: 2px;
	border-color: rgb(24, 24, 24);
	border-style: solid; */
}

.redText {
	color: red
}

.greenText {
	color: #21cc49;
}

.chartDiv {
	display: inline-block;
}

.chartDiv text {
    fill: white !important;
}

.calibrationBox {
	text-align: center;
	min-width: 400px;
}


.canvasBox {
	border-width: 3px;
	border-color: black;
	border-style: solid;
	max-width: max-content;
	min-width: 400px;
	padding: 0px;
	user-select: none;
}

.drCanvasBox {
	border-width: 3px;
	border-color: black;
	border-style: solid;
	user-select: none;
	max-width: 600px;
	min-width: 600px;
	background-color: rgb(33, 33, 36);
}

@media (max-width: 500px) {
	.canvasBox {
		min-width: 250px;
	}
}

@media (max-width: 700px) {
	.drCanvasBox {
		max-width: 500px;
		min-width: 250px;
	}
}

.drButtonBox {
	margin: 10px;
}

.llButtonBox {
	margin: 10px;
}

.guessBox {
	text-align: center;
	padding: 10px;
}

.responsiveImage {
	max-width: 100%;
	height: auto;
}

.responsiveImageCentered {
	max-width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}

.centerMe {
	margin-left: auto;
	margin-right: auto;
}

.spaceMe {
	padding-top: 20px;
}

.justifyMe {
	max-width: 800px;
	text-align: justify;
	text-justify: inter-word;
}

.grid {
	border-width: 3px;
	border-color: black;
	border-style: solid;
	max-width: max-content;
	min-width: 14cm;
	min-height: 14cm;
	padding: 0px;
	user-select: none;
	background-color: rgb(33, 33, 36);
}

.cell {
	width: 0.5cm;
	height: 0.5cm;
	padding: 0px;
}

.row {
	--bs-gutter-x: 0;
	/* padding: 0px; */
}

.pdl {
	min-width: 400px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cryptBox {
	padding: 10px;
	min-width: 300px;
}

.cryptForm {
	padding: 20px;
}

.form-check-input:focus {
    background-color:white;
    border-color: white;
	box-shadow: 0 0 0 0.2rem rgba(41, 168, 71, 0.2);
}

.form-check-input:checked {
    background-color:#28a745;
    border-color: #28a745;
}

.form-control:focus {
	border-color: #28a745;
	box-shadow: 0 0 0 0.2rem rgba(41, 168, 71, 0.2);
} 


.text-muted {
	color: rgba(255, 255, 255, 0.753) !important;
}

textarea.form-control {
	background-color: grey !important;
	color: white !important;
}

textarea.form-control-plaintext{
	background-color: grey !important;
	color: white !important;
}

input.form-control {
	background-color: grey !important;
	color: white !important;
}

input.form-control-plaintext {
	background-color: grey !important;
	color: white !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgba(255, 255, 255, 0.418) !important;
	opacity: 1; /* Firefox */
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: rgba(255, 255, 255, 0.418) !important;
	opacity: 1; /* Firefox */
}
  
::-ms-input-placeholder { /* Microsoft Edge */
	color: rgba(255, 255, 255, 0.418) !important;
	opacity: 1; /* Firefox */
}

.emph {
	color: #21cc49;
}

.scoreBox {
	text-align: center;
	padding: 10px;
}

.disclaimer {
	font-size: smaller;
}

.navbar-nav {
	margin-left: 5px;
}

.navbar-toggler {
	margin-left: 5px;
}